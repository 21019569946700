import { Observer } from 'mobx-react-lite';
import React from 'react';
import { enableInsights } from '../../env';
import { AnimatedLink } from '../AnimatedLink/AnimatedLink';
import Line from '../Line/Line';
import LogoPartialAxon from '../LogoPartialAxon';
import LogoPartialDivision from '../LogoPartialDivision';
import PageSection from '../PageSection/PageSection';
import { usePageContext } from '../PageTemplate/PageTemplate';
import './NavBar.scss';

type NavBarProps = {}

const NavBar: React.FC<NavBarProps> = props => {

  const page = usePageContext();

  return <Observer children={() => (
    <PageSection as="nav" id="NavBar" className="NavBar">
      <div className="NavBarSlotStart">
        <AnimatedLink className="NavBarPrimaryLogoLink" to="/" title="AxonDivision Home">
          <LogoPartialAxon />
        </AnimatedLink>
        <Line />
      </div>
      <div className="NavBarSlotEnd">
        <Line />
        <AnimatedLink to="/" title="AxonDivision Home">
          <LogoPartialDivision />
        </AnimatedLink>
      </div>
      <div className="NavBarSlotCenter">
        <div className="NavBarSiteNav">
          <AnimatedLink to="/apps" title="Apps">Apps</AnimatedLink>
          <Line />
          <AnimatedLink to="/websites/?sector=all" title="Websites" activeClassChecker={() => page.location?.pathname.indexOf('/websites/') === 0}>Websites</AnimatedLink>
          <Line />
          <AnimatedLink to="/design" title="Design">Design</AnimatedLink>
          <Line />
          {enableInsights && <>
            <AnimatedLink to="/insights" title="Insights">Insights</AnimatedLink>
            <Line />
          </>}
          <AnimatedLink to="/contact" title="Contact">Contact</AnimatedLink>
        </div>
      </div>
    </PageSection>
  )} />
}

export default NavBar;