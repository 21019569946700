/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import PropTypes from "prop-types";
import React from "react";
import { Helmet } from "react-helmet";
import socialImage from '../content/images/axon/axondivision-social-sharing-preview.jpg';
import useSiteMetadata from "../hooks/useSiteMetadata.hook";
import { usePageContext } from "./PageTemplate/PageTemplate";

export interface SEOProps {
  description?: string, 
  lang?: string, 
  meta?: JSX.IntrinsicElements["meta"][],
  title?: string,
  image?: string,
  keywords?: string[],
  location?: Location
}

const SEO = (props: React.PropsWithChildren<SEOProps>) => {

  const { description, lang, meta, title } = usePageContext() || props;
  const defaultTitle = 'AxonDivision';
  const siteMeta = useSiteMetadata();
  const metaDescription = description ?? siteMeta.description;

  // TODO: add social media images

  return (
    <Helmet
      htmlAttributes={{
        lang: lang ?? 'en',
      }}
      title={title}
      titleTemplate={defaultTitle ? `%s | ${defaultTitle}` : ''}
      link={([
        ...props.location ? [{
          rel: 'canonical',
          href: `${siteMeta.siteUrl}${props.location.pathname}`
        }] : [],
      ])}
      meta={([
        {
          name: 'viewport',
          content: 'initial-scale=1, viewport-fit=cover',
        },
        {
          name: `description`,
          content: metaDescription,
        },
        {
          name: `keywords`,
          content: props.keywords ?? siteMeta.keywords,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          property: `og:locale`,
          content: `en_IE`,
        },
        {
          property: `og:image`,
          content: props.image ?? socialImage,
        },
        {
          property: `og:image:width`,
          content: 1200,
        },
        {
          property: `og:image:height`,
          content: 627,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          // TODO
          content: ``,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
      ] as JSX.IntrinsicElements["meta"][]).concat(meta ?? [])}
    />
  )
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
}

export default SEO
