import { Observer } from 'mobx-react-lite';
import React from 'react';
import { useAppContext } from '../../controllers/app.controller';
import { enableInsights } from '../../env';
import { AnimatedLink } from '../AnimatedLink/AnimatedLink';
import Line from '../Line/Line';
import LogoPartialAxon from '../LogoPartialAxon';
import LogoPartialDivision from '../LogoPartialDivision';
import PageSection from '../PageSection/PageSection';
import PseudoLink from '../PseudoLink/PseudoLink';
import './StandardFooter.scss';

type StandardFooterProps = {}

const StandardFooter: React.FC<StandardFooterProps> = props => {

  const { UI } = useAppContext();

  return <Observer children={() => <>
    {/* { UI.viewport.width < 768 && <> */}
      {/* <Spacer size="2em" />
      <Line />
      <Spacer size="2em" /> */}
    {/* </>} */}
    <PageSection id="StandardFooter" as="footer" className="StandardFooter" observeVisibility>
      <header className="StandardFooterHeader">
        <h2><AnimatedLink to="/" title="Home"><LogoPartialAxon size={UI.viewport.width > 768 ? 72 : 36} /></AnimatedLink></h2>
      </header>
      <nav className="StandardFooterMajorLinks">
        <p>
          <AnimatedLink to="/apps" title="Apps"><strong><span>Apps</span></strong></AnimatedLink>
          <Line />
        </p>
        <p>
          <AnimatedLink to="/websites/?sector=all" title="Websites"><strong><span>Websites</span></strong></AnimatedLink>
          <Line />
        </p>
        <p>
          <AnimatedLink to="/design" title="Design"><strong><span>Design</span></strong></AnimatedLink>
          <Line />
        </p>
        {enableInsights && <p>
          <AnimatedLink to="/insights" title="Insights"><span>Insights</span></AnimatedLink>
          <Line />
        </p>}
        <p>
          <AnimatedLink to="/contact" title="Contact"><span>Contact</span></AnimatedLink>
          <Line />
        </p>
        {/* <p>
          <AnimatedLink to="/plans" title="Plans"><span>Plans</span></AnimatedLink>
          <Line />
        </p> */}
      </nav>
      <div className="StandardFooterMetaInfo">
        <p><strong><span>© <AnimatedLink to="/" title="AxonDivision Home">AxonDivision</AnimatedLink> Ltd.,</span> <span>All Rights Reserved.</span></strong></p>
        {/* <address><strong>Address:</strong> <span>2 Brighton Hall,</span> <span>Brighton Road, Foxrock, Dublin</span> <span>Republic of Ireland</span></address> */}
        <p><strong>Company Reg. No.: 480194</strong></p>
        <p>Connect with us: <a href="https://www.linkedin.com/company/axondivision" target="_blank" rel="noreferrer">LinkedIn</a></p>
      </div>
      <footer className="StandardFooterFooter">
        <ul className="StandardFooterMinorLinks">
          <li><AnimatedLink to="/data-protection-policy" title="Data Protection Policy">Data Protection Policy</AnimatedLink></li>
          <li><PseudoLink title="Privacy Settings" onClick={() => window.MANAGE_PRIVACY_SETTINGS()}>Privacy Settings</PseudoLink></li>
          <li><AnimatedLink to="/about" title="About">About</AnimatedLink></li>
          {/* <li><a href="/sitemap.xml" title="Sitemap" target="_blank">Sitemap</a></li> */}
        </ul>
      </footer>
      <div className="StandardFooterLogoPartialDivisionHolder">
        <LogoPartialDivision size={72} />
      </div>
    </PageSection>
  </>} />
}

export default StandardFooter;