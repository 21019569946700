import React from 'react';

type LogoPartialAxonProps = {
  size?: 144 | 72 | 36 | 18,
  strokeWidth?: number,
  stroke?: string,
  fill?: string,
}

const LogoPartialAxon: React.FC<LogoPartialAxonProps> = props => {
  const { size = 36, fill = "currentColor", stroke = 'none', strokeWidth = .5 } = props;
  const scalar = size / 144;
  return <svg className="LogoPartialAxon" width={584 * scalar} height={size} viewBox="0 0 584 144">
    <title>AxonDivision</title>
    <path 
      fillRule="evenodd" clipRule="evenodd"
      d="m367 143c39.212 0 71-31.788 71-71 0-39.212-31.788-71-71-71s-71 31.788-71 71c0 39.212 31.788 71 71 71zm0-35c16.016 0 29-16.566 29-37s-12.984-37-29-37-29 16.566-29 37 12.984 37 29 37zm-352.2 33h-14.804v-27l52.18-111 41.639-3e-5 52.18 111v27h-34.3l-25.951-55.581c-1.0513-2.2519-1.5962-4.7069-1.5962-7.192v-50.227h-1.0139l-22.069 51.696c-1.4683 3.4396-4.03 6.299-7.288 8.1353l-23.365 13.168v1h54.243l4.5625 9-33.865 19.27c-12.359 7.032-26.334 10.73-40.554 10.73zm234.91-138h40.287v15.25l-35.692 43.75h-8.308v1l44 47v31h-32l-20.468-23.88c-1.348-1.572-2.022-2.358-2.492-3.25-0.368-0.697-0.642-1.439-0.817-2.207-0.223-0.984-0.223-2.019-0.223-4.09v-28.573h-1l-45 62h-30v-30l33.542-42.998 7.458-0.0017v-1l-41-41.75v-22.25h32.287l28.713 29.5v10.5h1l29.713-40zm292.29 0h42v138h-38l-66-111h-1v1l17.866 50.778c0.751 2.1327 1.134 4.3772 1.134 6.6381v52.584h-42v-138h26l45.696 37.849c2.498 2.0694 4.456 4.7156 5.704 7.7103l20.6 49.44h1v-1l-12.587-61c-0.275-1.3298-0.413-2.6841-0.413-4.0419v-28.958z"
      fill={fill}
      stroke={stroke}
      strokeWidth={strokeWidth} 
    />
  </svg>
}

export default LogoPartialAxon;