import { reaction, when } from 'mobx';
import { Observer } from 'mobx-react-lite';
import React from 'react';
import { useAppContext } from '../../controllers/app.controller';
import { defaultTricolorPaths } from '../../controllers/decoTricolor.controller';
import { isBrowser } from '../../env';
import { useOnMount } from '../../hooks/lifecycle.hooks';
import { debounce } from '../../utils/debounce.utils';
import { useProps, useStore } from '../../utils/mobx.utils';
import { highPerf } from '../../utils/performance.utils';
import './TricolorMorphDef.scss';

type TricolorMorphDefProps = {
  mobile?: {
    R?: string,
    G?: string,
    B?: string,
  },
  desktop?: {
    R?: string,
    G?: string,
    B?: string,
  }
}

/**
 * This component renders the 'target' paths for the R/G/B tricolor background graphics to morph into. 
 * There should only be one of those elements rendered onto the page at the same time. 
 */
const TricolorMorphDef: React.FC<TricolorMorphDefProps> = props => {

  const { UI } = useAppContext();
  const p = useProps(props);
  const s = useStore(() => ({
    get useMobileRatio() {
      return isBrowser && UI.viewport.width < 640;
    },
    get width() {
      return s.useMobileRatio ? 375 : 1194;
    },
    get height() {
      return s.useMobileRatio ? 812 : 834;
    },
    get viewBox() {
      return [0,0,s.width,s.height].join(' ');
    },
    get def() {
      return s.useMobileRatio ? p.mobile ?? p.desktop : p.desktop ?? p.mobile;
    },
  }))

  useOnMount(() => {
    const morph = () => {
      UI.tricolor?.morph({
        red: '#TRICOLOR_R_DEST',
        green: '#TRICOLOR_G_DEST',
        blue: '#TRICOLOR_B_DEST',
        duration: highPerf ? (!s.def?.R && !s.def?.G && !s.def?.B ? .38 : 1) : 0,
      });
    }
    if (isBrowser) {
      when(() => UI.ready, morph);

      // since the browser must ask for permission to get `deviceOrientationEvent`s, we are not going to include the following in final release.

      // window.addEventListener('touchend', () => {
      //   DeviceOrientationEvent?.requestPermission?.().then(permissionState => {
      //     if (permissionState === 'granted') {
      //       const handleOrientation = (e: DeviceOrientationEvent) => {
      //         let beta = e.beta ?? 0;   // In degree in range [-180,180) around x axis
      //         let gamma = e.gamma ?? 0; // In degree in range [-90,  90) around y axis
      //         // Because we don't want to have the device upside down, we constrain the x value to the range [-90,90]
      //         if (beta > 90) { beta = 90 };
      //         if (beta < -90) { beta = -90 };
      //         Array.from(document.querySelectorAll<SVGPathElement>('.DecoTricolorContainer path')).forEach((path, i) => {
      //           const deltaX = gamma * ((i + 3) / 7);
      //           const deltaY = beta * ((i + 3) / 7);
      //           TweenLite.to(path, 1, { x: deltaX, y: deltaY });
      //         })
      //       }
      //       window.addEventListener('deviceorientation', handleOrientation);
      //     }
      //   })
      // }, { once: true })

      return reaction(
        () => s.useMobileRatio,
        debounce(morph, 500)
      )
    }
  })

  return <Observer children={() => (
    UI.ready ? <svg className="TricolorMorphDef" 
      width={s.width} height={s.height} 
      viewBox={s.viewBox} 
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d={s.def?.R ?? defaultTricolorPaths.R} id="TRICOLOR_R_DEST" />
      <path d={s.def?.G ?? defaultTricolorPaths.G} id="TRICOLOR_G_DEST" />
      <path d={s.def?.B ?? defaultTricolorPaths.B} id="TRICOLOR_B_DEST" />
    </svg> : null
  )} />

}

export default TricolorMorphDef;