import React from 'react';

type LogoPartialDivisionProps = {
  size?: 144 | 72 | 36 | 18,
  strokeWidth?: number,
  stroke?: string,
  fill?: string,
}

const LogoPartialDivision: React.FC<LogoPartialDivisionProps> = props => {
  const { size = 36, fill = 'none', stroke = 'currentColor', strokeWidth = .5 } = props;
  const scalar = size / 144;
  const relativeStrokeWidth = strokeWidth / scalar;
  return <svg className="LogoPartialDivision" width={880 * scalar} height={size} viewBox="0 0 880 144">
    <title>AxonDivision</title>
    <path
      fillRule="evenodd" clipRule="evenodd" 
      d="m661.01 143c39.213 0 71-31.788 71-71 0-39.212-31.787-71-71-71-39.212 0-71 31.788-71 71 0 39.212 31.788 71 71 71zm0-35c16.017 0 29-16.566 29-37s-12.983-37-29-37c-16.016 0-29 16.566-29 37s12.984 37 29 37zm-661.01-69h46.501c14.912 0 27 7.5 27 31s-12.088 31-27 31h-4.5006v-60.5l-33.795 11.265c-4.9001 1.6334-8.2053 6.219-8.2053 11.384v77.851h46.501c38.108 0 69-21 69-69s-30.893-69-69-69h-46.501v36zm181.5-36h-42v138h42v-138zm57.088 0h-38.088v24l36.472 114h52.056l36.472-114v-24h-38.088l-22.942 78-1.47 26h-1l-1.411-26-22.001-78zm146.91 0h-42v138h42v-138zm66.39 2e-5 54.018-2e-5v16.294l-3.276 16.293h-46.375c-4.367 0-10.086 2.4316-9.625 8.1631 0.482 6.0002 7.76 8.4185 22.977 13.474v2e-4l8e-3 0.0024c2.533 0.8417 5.286 1.7565 8.265 2.7731 20.875 7.125 33.216 22.824 29.125 48.625-3.058 19.284-16 32.375-42.875 32.375h-54.455v-16.5l4.455-17.5h39.875c10.125 0 14.375-1.438 14.812-6.938 0.438-5.4995-3.191-8.2431-24.937-15.312-25.188-8.1875-37.305-21.566-35.5-45.5 1.791-23.75 21.295-36.25 43.508-36.25zm120.12-2e-5h-42v138h42v-138zm264 0h42v138h-38l-66-111h-1v1l17.867 50.778c0.75 2.1327 1.133 4.3772 1.133 6.6381v52.584h-42v-138h26l45.696 37.849c2.499 2.0694 4.456 4.7156 5.704 7.7103l20.6 49.44h1v-1l-12.587-61c-0.274-1.3298-0.413-2.6841-0.413-4.0419v-28.958z"
      fill={fill}
      stroke={stroke}
      strokeWidth={relativeStrokeWidth}
    />
  </svg>
}

export default LogoPartialDivision;