import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { Power2, TweenLite } from 'gsap';
import { flow } from 'mobx';
import { Observer } from 'mobx-react-lite';
import React, { useRef } from 'react';
import ReactDOM from "react-dom";
import { useAppContext } from '../../controllers/app.controller';
import { isBuildTime } from '../../env';
import { useOnMount } from '../../hooks/lifecycle.hooks';
import { EntryMetaInfo, ImageWithMetaInfo } from '../../types/app.types';
import { isIE } from '../../utils/browsers.utils';
import { ColorPalette, ContextColor, getContextColorStyle, isBrightColor } from '../../utils/colors.utils';
import { makeDisposerController } from '../../utils/disposer.utils';
import { useProps, useStore } from '../../utils/mobx.utils';
import { registerParallaxEffect } from '../../utils/parallax.utils';
import { highPerf } from '../../utils/performance.utils';
import tick from '../../utils/waiters.utils';
import { usePageContext } from '../PageTemplate/PageTemplate';
import './ThemeSetter.scss';

export type ThemeSetterProps = {
  entryMeta?: EntryMetaInfo,
  backgroundColor?: string,
  backgroundImage?: ImageWithMetaInfo,
  foregroundColor?: string,
}

/**
 * Controls the transition of background graphics between pages.
 */
const ThemeSetter: React.FC<ThemeSetterProps> = props => {
  const page = usePageContext();
  const p = useProps(props);
  const { UI } = useAppContext();
  const s = useStore(() => ({
    get theme() {
      return p.entryMeta?.theme;
    },
    get backgroundColor() {
      return props.backgroundColor ?? s.theme?.backgroundColor ?? ColorPalette.dark
    },
    get foregroundColor() {
      return props.foregroundColor ?? s.theme?.foregroundColor ?? ColorPalette.ivory
    },
    backgroundContainer: null as Nullable<HTMLDivElement>,
  }))
  const backgroundImageContainerRef = useRef<HTMLDivElement>(null);
  useOnMount(() => {
    (flow(function* () {
      if (isBuildTime) return;
      const contextColorDefinitions = {
        ...getContextColorStyle(ContextColor.bg, s.backgroundColor),
        ...getContextColorStyle(ContextColor.fg, s.foregroundColor),
      }
      Object.entries(contextColorDefinitions).forEach(([key, value]) => {
        document.documentElement.style.setProperty(key, value);
      })
      document.documentElement.classList.toggle('light', isBrightColor(s.backgroundColor));
      document.documentElement.classList.toggle('dark', isIE || isBrightColor(s.foregroundColor));
      UI.ui.isDarkTheme = isBrightColor(s.foregroundColor);
      document.documentElement.classList.toggle('blue', s.backgroundColor === ColorPalette.blue);
      const d = makeDisposerController();
      const existingContainer = document.getElementById('__BackgroundContainer') as HTMLDivElement | null;
      const container: HTMLDivElement = existingContainer ?? document.createElement('div');
      if (!existingContainer) {
        container.setAttribute('id', '__BackgroundContainer');
        document.body.prepend(container);
      }
      s.backgroundContainer = container;
      yield tick();
      if (backgroundImageContainerRef.current) {
        TweenLite.set(backgroundImageContainerRef.current, { opacity: 0 })
        TweenLite.fromTo(backgroundImageContainerRef.current, highPerf ? 1 : 0, {
          opacity: 0,
          scale: 1.2,
        }, {
          opacity: 1,
          scale: 1,
          ease: Power2.easeOut,
        })
      }
      registerParallaxEffect(backgroundImageContainerRef, {
        id: 'backgroundImageContainer',
        depth: -.05,
        shouldDisable: () => UI.viewport.width > 768,
      })
      return d.disposer;
    }))()
  })
  const image = getImage(props.backgroundImage?.image);
  return <Observer children={() => (
    <>
      { (s.backgroundContainer && (image || s.backgroundColor)) ? ReactDOM.createPortal(
        <div
          className="ThemeSetter__background-layer"
          data-transition-status={page.transitionStatus}
          ref={backgroundImageContainerRef}
          style={{ backgroundColor: s.backgroundColor }}
        >
          {image && <div
            className="ThemeSetter__background-layer__image-wrapper"
            data-tall-screen={UI.viewport.width / UI.viewport.height < 1}
            style={{ opacity: s.theme?.backgroundImageOpacity ?? .3}}
          >
            <GatsbyImage
              image={image}
              title={props.backgroundImage?.title}
              alt={props.backgroundImage?.alt ?? 'Page Background Image'}
              objectFit="cover"
              objectPosition="center"
            />
        </div>}
        </div>,
        s.backgroundContainer
      ) : null }
    </>
  )} />
}

export default ThemeSetter;